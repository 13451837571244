<template>
  <v-col
    cols="12"
    md="6"
    class="mt-3"
  >
    <v-row class="justify-space-between mx-0">
      <div>
        <h3>{{stockQuote.symbol}}</h3>
        <small>{{stockQuote.description}}</small>
      </div>
      <div>
        <v-btn
          elevation="2"
          :to="{name:'options', params: {ticker: $route.params.ticker}}"
          color="success"
        >Get Options</v-btn>
      </div>
    </v-row>
    <v-row
      class="black--text"
    >
      <v-col
        align="center"
      >
        <apexchart
          :type="typeOfGraph"
          :series="apexdata"
          :options="options"
          class="chart-style"
        >
        </apexchart>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-switch
        v-model="linegraph"
        :label="linegraph ? 'Line Graph' : 'Candle Stick'"
      ></v-switch>
      </v-col>
    </v-row>
    <v-row>
      <h4 class="mx-auto">Info</h4>
    </v-row>
    <div class="text-center">
      <v-row>
        <v-col>
          <div class="d-flex flex-column">
            <h4>Open</h4>
            <small>{{stockQuote.open}}</small>
          </div>
        </v-col>
        <v-col>
          <div class="d-flex flex-column">
            <h4>Close</h4>
            <small>{{stockQuote.close}}</small>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="d-flex flex-column">
            <h4>52-Low</h4>
            <small>{{stockQuote.open}}</small>
          </div>
        </v-col>
        <v-col>
          <div class="d-flex flex-column">
            <h4>52-High</h4>
            <small>{{stockQuote.close}}</small>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-col>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  name: 'Overview',
  data: () => ({
    apexdata: [],
    options: {
      chart: {
        id: 'vuechart-example'
      },
      xaxis: {
        type: 'datetime'
      }
    },
    linegraph: true
  }),
  components: {
    apexchart: VueApexCharts
  },
  computed: {
    stockQuote () {
      return this.$store.getters.stockQuote
    },
    stockTimeSeries () {
      return this.$store.getters.stockTimeSeries
    },
    typeOfGraph () {
      return this.linegraph ? 'line' : 'candlestick'
    }
  },
  mounted () {
    (async () => {
      this.$store.dispatch('stockQuote', this.$route.params.ticker)
      await this.$store.dispatch('stockTimeSeries', this.$route.params.ticker)
      this.apexdata = [{
        name: 'stockSeries',
        data: await this.stockTimeSeries?.map(item => (
          [new Date(item.time).getTime(), [item.open, item.high, item.low, item.close]]
        ))
      }]
    })()
  }
}
</script>

<style>
.chart-style{
  width: 90%;
}
</style>
